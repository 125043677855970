import React                                        from 'react';
import                                                   '../../../css/components/projectPage/ProjectTextPicture.css';
import {ReactComponent as SvgArrow} from "../../../img/icons/arrow.svg";


export class ProjectTextPicture extends React.PureComponent{

    render() {
        return(
            <div className = "p_text_pic_always">
                {this.props.imgLeft?
                    <div className = "p_text_picture_container_L">
                        <img src = {this.props.img} alt = ""/>
                        <div className = "p_text_pic_content">
                            {this.props.overline? <h2>{this.props.overline}</h2> : ''}
                            {this.props.headline? <h3>{this.props.headline}</h3> : ''}
                            <p>{this.props.content}</p>

                            {this.props.link?
                                <div className="p_text_pic_link">
                                    <SvgArrow/>
                                    <a href={this.props.link}
                                       target="_blank"
                                       rel="noreferrer"
                                    >
                                        <h5>{this.props.linkText}</h5>
                                    </a>
                                </div>
                            : ''}
                        </div>
                    </div>

                    :
                    <div className = "p_text_picture_container_R">
                        <img src = {this.props.img} alt = ""/>
                        <div className= "p_text_pic_content">
                            {this.props.overline? <h2>{this.props.overline}</h2> : ''}
                            {this.props.headline? <h3>{this.props.headline}</h3> : ''}
                            <p>{this.props.content}</p>

                            {this.props.link?
                                <div className="p_text_pic_link">
                                    <SvgArrow/>
                                    <a href={this.props.link}
                                       target="_blank"
                                       rel="noreferrer"
                                    >
                                        <h5>{this.props.linkText}</h5>
                                    </a>
                                </div>
                                : ''}

                        </div>
                    </div>

                }

            </div>

        )
    }
}