import React                                        from 'react';
import {ProjectBackground}  from '../../components/projectPage/ProjectBackground';
import {ProjectHeader}      from '../../components/projectPage/ProjectHeader';
import {ProjectTeaser}      from '../../components/projectPage/ProjectTeaser';
import {ProjectTextPicture} from '../../components/projectPage/ProjectTextPicture';


import care1 from '../../../img/projects/04_motionStud/Text_Bild_TC_A.jpg';
import care2 from '../../../img/projects/04_motionStud/Text_Bild_TC_B.jpg';
import care3 from '../../../img/projects/04_motionStud/Text_Bild_TC_C.jpg';
import care4 from '../../../img/projects/04_motionStud/Teaser_2_TC_A.jpg';
import care5 from '../../../img/projects/04_motionStud/Teaser_2_TC_B.jpg';
import ss1   from '../../../img/projects/04_motionStud/Text_Bild_SS_A.jpg';
import ss2   from '../../../img/projects/04_motionStud/Text_Bild_SS_B.jpg';
import ss3   from '../../../img/projects/04_motionStud/Teaser_2_SS_A.jpg';
import ss4   from '../../../img/projects/04_motionStud/Teaser_2_SS_B.jpg';
import zero1 from '../../../img/projects/04_motionStud/Teaser_2_01_A.jpg';
import zero2 from '../../../img/projects/04_motionStud/Teaser_2_01_B.jpg';




export class MotionStudies extends React.PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {

        let skills = this.props.config.skills;
        let tools  = this.props.config.tools;
        let team   = this.props.config.team;

        return (
            <div>
                <ProjectBackground
                    colors = {[this.props.defaultLights.red, this.props.defaultLights.red, this.props.defaultLights.yellow]}
                />

                <ProjectHeader
                    headline    = "Take Care"
                    year        = "2019"
                    teaser      = "https://www.youtube-nocookie.com/embed/ClMrnuAlojo"
                    teaserALT   = "Take care animation film"
                    shortInfo   = "A short 3D animated film aimed to bring awareness to climate change. It's about a bored little penguin living in the Arctic who finds a special friend to spend his time with. But just when he was happier than ever, an unexpected event turns his world upside down. The shock factor combined with compassion and sympathy for the penguin should motivate the viewer to take action against this world wide dilemma.
                                  "
                    skills      = {[skills.model, skills.animationD, skills.story, skills.edit, skills.sound]}
                    tools       = {[tools.blender, tools.a_ae, tools.a_pr, tools.a_au]}
                    team        = {[team.flo, team.simon]}

                    scrollTo    = {() => this.props.scrollTo('navProjects')}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {care1}
                    headline    = "Research"
                    content     = "After we decided to make a short film about the climate crisis, we focused on two things. On one side it was important to find out biological facts about the penguins and the Arctic to create a realistic setting. On the other side the perfect animation style had to be found to fit with our capabilities and the overall message of the film.
                    "
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {care2}
                    headline    = "Storyboard"
                    content     = "Working in a team requires that everyone is on the same page. A storyboard helps immensely to visualize the whole composition. So everyone can work on a different part in order to split the workload. It also helps to detect problems in the sequence of events in early stages and lets us correct them to stay true to our plot.
                    "
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {care3}
                    headline    = "Creation"
                    content     = "After modeling the 3D objects, we were able to start the animation in a master scene. The rendered frames from Blender were imported to Premiere Pro for final timing and editing. To enhance the wintry setting, a snow effect was added from After Effects and the color grading was reworked.
                    "
                />

                <ProjectTeaser
                    pictures    = {[care4, care5]}
                />

                <ProjectHeader
                    headline    = '"Super Scheisse"'
                    year        = "2019"
                    teaser      = "https://www.youtube-nocookie.com/embed/Q5AGm8wfkm4"
                    teaserALT   = "Stop-motion commercial"
                    shortInfo   = "A stop-motion election commercial for the fictional party: “The party for the True, the Good and the Beautiful ”. The concept was to convince voters to give them their vote in the next election by showing them an exaggerated version of the current problems within the country.
                    "
                    skills      = {[skills.research, skills.story, skills.edit, skills.sound, skills.foto]}
                    tools       = {[tools.a_pr, tools.a_au, tools.dragon]}
                    team        = {[team.flo, team.simon, team.toska, team.kathy]}

                    scrollTo    = {() => this.props.scrollTo('navProjects')}
                    noBackButton = {true}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {ss1}
                    headline    = "Storyboard"
                    content     = "After analyzing other election commercials and determining the key messages of the party, we started sketching out our storyboard. The dialog of the speaker was also written ahead of time, so that the speaking time could be considered during the stop-motion photography.
                    "
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {ss2}
                    headline    = "Creation"
                    content     = "The cut-out pieces were moved on an animation stand with a camera facing them from the top. The stop-motion software Dragonframe helped to optimize and visualize the movement of the elements. To match the footage with the recorded voice, Premiere Pro and Audition were used for the final touches.
                    "
                />

                <ProjectTeaser
                    pictures    = {[ss3, ss4]}
                />

                <ProjectHeader
                    headline    = "0111011"
                    year        = "2018"
                    teaser      = "https://www.youtube-nocookie.com/embed/bgQkRCeEwnk"
                    teaserALT   = "music matching animations"
                    shortInfo   = "Visualization of a modular built soundtrack by using only minimalistic geometric shapes. It starts off with several simple compositions for the different sound segments. Later on, the motion graphics get to mix with each other to build up to the acceleration of the music. This works seamlessly on it's own because the soundtrack itself reuses and mixes its segments to make it more interesting and exciting.
                    "

                    skills      = {[skills.animation]}
                    tools       = {[tools.a_ae]}
                    team        = {[team.flo, team.simon]}

                    scrollTo    = {() => this.props.scrollTo('navProjects')}
                    noBackButton = {true}
                />

                <ProjectTeaser
                    pictures    = {[zero1, zero2]}
                />


            </div>
        )
    }
}


