import React                                        from 'react';
import                                                   '../../css/components/ProjectEntry.css';
import {Link}                                       from 'react-router-dom';
import {ReactComponent as SvgArrow}                 from '../../img/icons/arrow.svg';

export class ProjectEntry extends React.PureComponent{

    constructor(props) {
        super(props);

        this.state = {
            smallSize:  false
        };
    }

    componentDidMount() {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        document.removeEventListener('scroll', () => this.handleEvent());
    }


    handleEvent = (event) =>{

        // Desktop
        if (this.props.isMobile === false) {
            this.props.changeLightning();
            // If it is a device with width over 1000;
            if (window.innerWidth >= 1000) {
                this.props.changeInfo(this.props.infoText);
            }

        // Mobile
        } else if (this.props.isMobile === true && this.el !== null) {
            let middle = window.innerHeight / 2;
            let elStart = this.el.getBoundingClientRect().top;
            let elHeight = this.el.getBoundingClientRect().height;

            if (middle >= elStart && middle < elStart + elHeight) {
                this.props.changeLightning();
                this.el.id = 'mobileHover';

                // If it is a device with width over 1000;
                if (window.innerWidth >= 1000) {
                    this.props.changeInfo(this.props.infoText);
                }
            } else {
                this.el.id = '';
            }
        }

    }

    handleWindowResize = () =>{

        if(this.props.isMobile){
            document.addEventListener('scroll', (event) => this.handleEvent(event));

        }else{
            this.el.id = '';
            document.removeEventListener('scroll', (event) => this.handleEvent(event));
        }

    }


    render() {

        return(
            <Link  to      = {this.props.link}
                   onClick = {this.props.hideInfo}
            >

                <div className = "entryContainer"
                     ref = {ref => (this.el = ref)}
                     onMouseEnter = {(event) => this.handleEvent(event)}
                     onMouseLeave = {this.props.hideInfo}
                >

                    <h2 className = "year">{this.props.year}</h2>


                    <h6 className="keys">
                        {this.props.key1 + " "}
                        {this.props.key2 ? <p>&bull; {this.props.key2 + " "}</p> : ""}
                        {this.props.key3 ? <p>&nbsp;&bull; {this.props.key3}</p> : ""}
                    </h6>

                    <div className = "gradient"
                         style     = {{
                             background: 'linear-gradient(' + 180 + 'deg,' +
                                 this.props.keygradient[0] + ' 0%,' +
                                 this.props.keygradient[1] + ' 50%,' +
                                 this.props.keygradient[2] + ' 100%)'
                         }}
                    />

                    <h1>{this.props.title}</h1>

                    {window.innerWidth <= 1000?
                        <p className= "infoText">
                            {this.props.infoText}
                        </p>
                        : ''}

                    <div className = "projectLink">
                        <SvgArrow className = "linkIcon"/>
                        <h5>view this case</h5>
                    </div>

                </div>

            </Link>
        )
    }
}