import React                                        from 'react';
import {ProjectBackground}  from '../../components/projectPage/ProjectBackground';
import {ProjectHeader}      from '../../components/projectPage/ProjectHeader';
import {ProjectTeaser}      from '../../components/projectPage/ProjectTeaser';
import {ProjectTextPicture} from '../../components/projectPage/ProjectTextPicture';

import research1 from '../../../img/projects/02_fundus/Teaser_2_Research_A.jpg';
import research2 from '../../../img/projects/02_fundus/Teaser_2_Research_B.jpg';
import research3 from '../../../img/projects/02_fundus/Text_Bild_Research.jpg';
import concept1  from '../../../img/projects/02_fundus/Teaser_1_Concept.jpg';
import concept2  from '../../../img/projects/02_fundus/Text_Bild_Concept.jpg';
import ui1       from '../../../img/projects/02_fundus/Text_Bild_UI_A.jpg';
import ui2       from '../../../img/projects/02_fundus/Teaser_2_UI_A.jpg';
import ui3       from '../../../img/projects/02_fundus/Teaser_2_UI_B.jpg';
import ui4       from '../../../img/projects/02_fundus/Text_Bild_UI_B.jpg';
import ui5       from '../../../img/projects/02_fundus/Teaser_1_UI.jpg';
import proto     from '../../../img/projects/02_fundus/Text_Bild_Proto.jpg';
import test      from '../../../img/projects/02_fundus/Text_Bild_Test.jpg';


export class Fundus extends React.PureComponent{

    componentDidMount() {
        window.scrollTo(0,0);
    }


    render() {

        let skills = this.props.config.skills;
        let tools  = this.props.config.tools;
        let team   = this.props.config.team;
        let client = this.props.config.client;

        return(
            <div>
                <ProjectBackground
                    colors = {[this.props.defaultLights.yellow, this.props.defaultLights.yellow, this.props.defaultLights.blue]}
                />

                <ProjectHeader
                    headline    = "Fundus"
                    year        = "2020"
                    teaser      = "https://www.youtube-nocookie.com/embed/6CxrQXjLMCQ?"
                    teaserALT   = "Info Video Fundus"
                    shortInfo   = "A gigantic depot and a high number of artifacts that have to be maintained make the daily inventory management time-consuming. This is a problem for many curators in German museums and all over the world. The web-app Fundus supports the employees of the museum in finding and assigning their holdings in a matter of seconds.
                                  "
                    skills      = {[skills.ux, skills.research, skills.project]}
                    tools       = {[tools.figma, tools.miro, tools.jira]}
                    team        = {[team.rike, team.toska, team.kathy, team.kraska, team.pat, team.domi]}
                    client      = {[client.museum]}
                    scrollTo    = {() => this.props.scrollTo('navProjects')}
                />

                <ProjectTeaser
                    pictures    = {[research1, research2]}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {research3}
                    headline    = "Research"
                    content     = "Only a small part of all artifacts a museum owns are displayed to the public. Most objects are stored in huge depots. In order to help the employees manage their inventory in a digital way, it was critical to understand the current storage system and workflow. Through several meetings with the museum staff, we sketched out the sequence of events of searching, finding and repositioning objects. By creating Personas, we made sure that the application adapted flawlessly to the target group as development proceeds.
                                  "
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {concept2}
                    headline    = "Concept"
                    content     = "Thanks to the team's extensive research, the basic functions of the app were clear and ready to illustrate by using Wireframes. They helped to visualize a user flow, which was important to determine if the concept ideas worked. The team decided to use Material Design templates for the overall user interface in order to use the React UI framework Material UI. Since the focus was to have a working coded prototype within 5 months, this was critical for a fast development strategy.
                    "
                />

                <ProjectTeaser
                    pictures    = {[concept1]}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {ui1}
                    headline    = "Assign location"
                    content     = "A main feature of Fundus is the location registration. The focus was on the ease of use of the app, taking into account the greatest possible mobility. Every location like shelves, drawers or cabinets are provided with a QR-code which can be scanned with the web-app via any onsite mobile device. This way every artifact and it's location is now registered with their individual identifier and easy to be retrieved and assigned to the next exhibit.
                    "
                />

                <ProjectTeaser
                    pictures    = {[ui2, ui3]}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {ui4}
                    headline    = "Find artifacts"
                    content     = "Another important aspect is the retrieval of objects in the depot. Fundus offers several search options in which items and their storage location can be found by inventory number, location or free text input. In addition, there is a detailed view for each artifact, in which data such as dimensions, materials and other descriptions are clearly displayed.
                    "
                />

                <ProjectTeaser
                    pictures    = {[ui5]}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {proto}
                    headline    = "Prototype"
                    content     = "Having a working prototype in Figma made it easy to go through the different features of the web-app with the whole team and the museum staff. This made sure that everyone was on the same page and communication errors could be excluded. Feel free to take a look at our work.
                                  "
                    link        = "https://www.figma.com/proto/KLMiWCjiVPf3wdGgs0tQ7N/Fundus-App?node-id=673%3A24046&viewport=1912%2C-283%2C0.18890905380249023&scaling=scale-down"
                    linkText    = "Figma prototype"
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {test}
                    headline    = "Implementation & User Test"
                    content     = "Thanks to the team effort, it was possible to present a usable web-app at the end of the semester. The evaluation of the user tests confirmed the increase in efficiency in the curators daily work process. The project overall received great feedback from the museum staff.
                    "
                />

            </div>
        )
    }
}

