import React                                        from 'react';
import                                                   '../../css/components/Dialog.css';

import {ReactComponent as SvgClose}                 from '../../img/icons/close.svg';
import {ReactComponent as SvgArrow}                 from '../../img/icons/arrow.svg';

import {ReactComponent as SvgHeart}                 from '../../img/icons/heart.svg';

export class Dialog extends React.PureComponent{


    componentDidMount() {
        if(this.props.link){
            //open pop-up
            window.setTimeout(()=>{
                window.open(this.props.link);
            }, 2000);
        }

    }

    render() {
        let mapTrigger = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
        let pLeftMax, pBottomMax, pBottomMin;

        if(window.innerWidth <= 760){
            pLeftMax   = 90;
            pBottomMax = 30;
            pBottomMin = 2;
        }else{
            pLeftMax   = 45;
            pBottomMax = 30;
            pBottomMin = 5;
        }

        return(
            <div className = "dialogFullPage">

                <div className="dialogContainer">
                    <h1>Spread<br/>some<br/>love</h1>

                    <div className="closeDialog"
                         onClick={this.props.closeDialog}
                    >
                        <SvgClose/>
                    </div>

                    <div className="dialogArrow">
                        <SvgArrow/>
                    </div>

                    <div className="content">
                        {this.props.link ?
                            <a href={this.props.link} target="_blank" rel="noreferrer">
                                <h5>{this.props.content}</h5>
                            </a>
                            :
                            <h5>{this.props.content}</h5>
                        }
                    </div>

                    <div className="particles">
                        {
                            mapTrigger.map((key) => {
                                return (<span className="hearts"
                                              style={{
                                                  left: Math.random() * pLeftMax + '%',
                                                  bottom: Math.random() * pBottomMax + pBottomMin + '%',
                                                  animationDelay: Math.random() * 3 + 's'
                                              }}
                                              key={key}
                                    >
                                        <SvgHeart
                                            style={{
                                                fill: '#e52e51',
                                                width: Math.random() * 30 + 5 + 'px',
                                                height: '100%'
                                            }}
                                        />
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="dialogBackground"
                     onClick={this.props.closeDialog}
                />
            </div>

        )
    }
}