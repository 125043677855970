import React                                        from 'react';
import {ProjectBackground}  from '../../components/projectPage/ProjectBackground';
import {ProjectHeader}      from '../../components/projectPage/ProjectHeader';
import {ProjectTeaser}      from '../../components/projectPage/ProjectTeaser';

import header from '../../../img/projects/07_verdana/Project_Header.jpg';
import teaser from '../../../img/projects/07_verdana/Teaser1.jpg';




export class Verdana extends React.PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {

        let skills = this.props.config.skills;
        let tools = this.props.config.tools;

        return (
            <div>
                <ProjectBackground
                    colors={[this.props.defaultLights.yellow, this.props.defaultLights.yellow, this.props.defaultLights.purple]}
                />

                <ProjectHeader
                    headline = "Verdana Typo Website"
                    year     = "2018"
                    teaser   = {header}
                    teaserALT="Verdana Typo Website"
                    shortInfo="The main intention was to create a website, which matches the characteristics of the font Verdana and conveys researched information about the typeface interactively. The difficulty was to integrate the interactions in a way that they create an additional benefit for the user. Check out the prototype and learn something about Verdana.
                    "
                    skills={[skills.research, skills.ux]}
                    tools={[tools.a_xd, tools.a_ps, tools.marvel]}

                    link     = "https://marvelapp.com/prototype/3425die/screen/41361148"
                    linkText = "Marvel Prototype"

                    scrollTo={() => this.props.scrollTo('navProjects')}
                />

                <ProjectTeaser
                    pictures = {[teaser]}
                />

            </div>
        )
    }
}