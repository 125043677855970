import React                                        from 'react';
import                                                   '../../../css/components/projectPage/ProjectHeader.css';
import {Link} from "react-router-dom";

import {ReactComponent as SvgArrowLeft}                  from '../../../img/icons/arrow_left.svg';
import {ReactComponent as SvgArrow}                      from '../../../img/icons/arrow.svg';

import gradientCircle from '../../../img/icons/gradient_circle.png';


export class ProjectHeader extends React.PureComponent{


    render() {

        let isVideo = this.props.teaser.includes('youtube');
        let overviewEntries = 1;

        return(
            <div className = "p_header_container"
            >
                <h1 className = "p_headline">{this.props.headline}</h1>
                <h2 className = "p_year">{this.props.year}</h2>

                <div className = "p_teaser">
                    {isVideo?
                        <iframe width = "100%" height = "100%" src = {this.props.teaser}
                                title = "YouTube video player" frameBorder = "0"
                                allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                        />
                        :
                        <img src = {this.props.teaser} alt = {this.props.teaserAlt}/>
                    }
                </div>

                <div className = "p_shortInfo">
                    <p>{this.props.shortInfo}</p>
                    {this.props.link?
                        <div className="p_header_link">
                            <SvgArrow/>
                            <a href={this.props.link}
                               target="_blank"
                               rel="noreferrer"
                            >
                                <h5>{this.props.linkText}</h5>
                            </a>
                        </div>
                        :
                        ''
                    }
                </div>


                {this.props.noBackButton?
                    ''
               :
                    <Link to = "/"
                          onClick   = {this.props.scrollTo}
                          className = "p_backIcon"
                    >
                        <div className = "p_backIcon_frame">
                            <img src = {gradientCircle} id = "p_circle" alt=""/>
                            <SvgArrowLeft id = "p_arrow"/>
                        </div>
                    </Link>
                }



                <div className= "p_listOverview">
                    {Array.isArray(this.props.skills) ?
                        <div className={'p_entry_' + overviewEntries++}>
                            <h2>Skills</h2>
                            {this.props.skills.map((e, i) => {
                                return (<p key={e.text + i}>{e.text}</p>)
                            })}
                        </div>
                        :
                        ''
                    }

                    {Array.isArray(this.props.tools) ?
                        <div className={'p_entry_' + overviewEntries++}>
                            <h2>Tools</h2>
                            {this.props.tools.map((e, i) => {
                                return (
                                    <a href    = {e.link}
                                       target  = "_blank"
                                       rel     = "noreferrer"
                                       key={e.text + i}
                                    >
                                        <p>{e.text}</p>
                                    </a>
                                    )
                            })}
                        </div>
                        :
                        ''
                    }

                    {Array.isArray(this.props.team) ?
                        <div className={'p_entry_' + overviewEntries++}>
                            <h2>Team</h2>
                            {this.props.team.map((e, i) => {
                                return (<p key={e.text + i }>{e.text}</p>)
                            })}
                        </div>
                        :
                        ''
                    }

                    {Array.isArray(this.props.client) ?
                        <div className={'p_entry_' + overviewEntries++}>
                            <h2>Client</h2>
                            {this.props.client.map((e, i) => {
                                return (
                                    <a href    = {e.link}
                                       target  = "_blank"
                                       rel     = "noreferrer"
                                       key={e.text + i}
                                    >
                                        <p>{e.text}</p>
                                    </a>
                                )
                            })}
                        </div>
                        :
                        ''
                    }

                    {Array.isArray(this.props.appreciation) ?
                        <div className={'p_entry_' + overviewEntries++}>
                            <h2>Appreciation</h2>
                            {this.props.appreciation.map((e, i) => {
                                return (
                                    <a href    = {e.link}
                                       target  = "_blank"
                                       rel     = "noreferrer"
                                       key={e.text + i}
                                    >
                                        <p>{e.text}</p>
                                    </a>
                                )
                            })}
                        </div>
                        :
                        ''
                    }

                </div>
            </div>
        )
    }
}