import React                                        from 'react';
import                                                   '../../css/ImprintData.css';

import background from '../../img/icons/wave.svg';

export class PageNotFound extends React.PureComponent{


    render() {
        return(
            <div className = "imDa_Grid"
                 style = {{height: window.innerHeight}}
            >
                <div className= "imprint_container">
                    <h1>404 Error</h1>
                    <h2>Woops, looks like this page was removed or doesn't exist.</h2>

                </div>
                <div className= "imDa_background"
                     style={{
                         backgroundImage: 'url('+background+')'
                     }}
                />
            </div>
        )
    }
}