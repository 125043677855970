import React                                        from 'react';
import {ProjectBackground}  from '../../components/projectPage/ProjectBackground';
import {ProjectHeader}      from '../../components/projectPage/ProjectHeader';
import {ProjectTextPicture} from '../../components/projectPage/ProjectTextPicture';

import header    from '../../../img/projects/03_samurai/Project_Header.jpg';
import img1      from '../../../img/projects/03_samurai/Text_Bild_A.jpg';
import img2      from '../../../img/projects/03_samurai/Text_Bild_B.jpg';
import img3      from '../../../img/projects/03_samurai/Text_Bild_C.jpg';
import img4      from '../../../img/projects/03_samurai/Text_Bild_D.jpg';


export class FruitSamurai extends React.PureComponent{

    componentDidMount() {
        window.scrollTo(0,0);
    }


    render() {

        let skills = this.props.config.skills;
        let tools  = this.props.config.tools;

        return(
            <div>
                <ProjectBackground
                    colors = {[this.props.defaultLights.blue, this.props.defaultLights.purple, this.props.defaultLights.blue]}
                />

                <ProjectHeader
                    headline    = "Fruit Samurai"
                    year        = "2020"
                    teaser      = {header}
                    teaserALT   = "Desktop with the game Fruit Samurai"
                    shortInfo   = "In this online game, the players slip into the role of a samurai carrying a sharp blade. They then get faced with more and more fruits flying towards them, which need to be cut up. Caution! There are also bombs mixed with the fruits, which explode when touched. Beat your high score! Try it out by yourself on any desktop device.
                                  "
                    skills      = {[skills.code, skills.game]}
                    tools       = {[tools.pixi, tools.webpack, tools.a_ai]}

                    scrollTo    = {() => this.props.scrollTo('navProjects')}

                    link        = {window.innerWidth < 1000? "" : "https://david-kaipf.de/games/index01.html"}
                    linkText    = "Click here to play!"
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {img1}
                    headline    = "Start the game"
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {img2}
                    headline    = "Don't miss any fruits"
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {img3}
                    headline    = "Avoid the bombs"
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {img4}
                    headline    = "Compare with your friends"
                />


            </div>
        )
    }
}