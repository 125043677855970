import React                                        from 'react';
import                                                   '../../../css/components/projectPage/ProjectTeaser.css';

export class ProjectTeaser extends React.PureComponent{

    render() {
        let pictureCount = this.props.pictures.length;

        return(
            <div className = {'p_teaser_container_' + pictureCount}
                 id        = "p_teaser_container_all"
            >
                {this.props.pictures.map((src, index) =>{
                    return (<img src={src} alt="" className={'picture' + (index + 1)} key={index}/>)
                })}

            </div>
        )
    }
}