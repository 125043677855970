import React                                        from 'react';
import                                                   '../../../css/components/projectPage/ProjectVideo.css';

export class ProjectVideo extends React.PureComponent{

    render() {
        return(
            <div className= "p_video">
                {this.props.discription? <h2>{this.props.discription}</h2> : '' }

                <iframe width = "100%" height = "100%" src = {this.props.videoLink}
                        title = "YouTube video player" frameBorder = "0"
                        allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                />

            </div>
        )
    }
}