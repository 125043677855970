import React                                        from 'react';
import                                                   '../../../css/modules/AboutMe.css';
import profilePic                                   from '../../../img/profile.png';
import {LogoAnimation} from "../../components/LogoAnimation";

export class AboutMe extends React.PureComponent{


    componentDidMount() {
        this.props.setLoad(true);
    }

    componentWillUnmount() {
        this.props.setLoad(false);
    }

    render() {
        return(
            <div
                className = "aboutContainer"
            >
                <img src={profilePic} alt = "David Kaipf" className = "profilePic"/>

                <LogoAnimation
                    className = "logoAnimation"
                />

                <div className = "textBox">
                    <h2>Who I am</h2>
                    <h1>Creative<br/>Logician</h1>
                    <p>
                        Hello, I’m David. I’m a digital / virtual Designer and Programmer based in Germany. I recently graduated with a degree in Bachelor of Arts from the University of Applied Sciences Augsburg.
                        <br/>
                        I studied Interactive Media, which is an exciting combination of code and design that matches perfectly with my logical and creative mind.
                        <br/>
                        I’ve been said to be a very positive and open-minded team leader. During group projects I often take the leading position, because I can keep a cool head even if it gets stressful.
                        Apart from that, I love to learn and create new things every day and can’t wait to face new challenges.
                    </p>
                </div>

            </div>
        )
    }
}