import React                                        from 'react';
import {Link}                                       from 'react-router-dom';
import                                                   '../../css/components/Menu.css';

import {ReactComponent as SvgArrow}                 from '../../img/icons/arrow.svg';
import {LetsTalkAnimation}                          from './LetsTalkAnimation';

export class Menu extends React.PureComponent{

    constructor(props) {
        super(props);
        this.year = new Date().getFullYear();
    }

    render() {
        return(
            <div className = "menuContainer"
                 id        = {this.props.visibleMenu? 'show' : 'hide'}
            >

                <a href      = "mailto:hello@david-kaipf.de"
                   className = "bigTalk"
                   onClick = {(e) => this.props.openDialog('Mail me at: hello@david-kaipf.de')}
                >
                    <LetsTalkAnimation/>
                </a>

                <Link to        = "/"
                      className = "navProjects"
                      onClick   = {() => this.props.closeAndScroll('navProjects')}
                >
                    <h1>What I do</h1>
                    <SvgArrow className = "linkIcon"/>
                    <h5>view projects</h5>
                </Link>

                <Link to        = "/"
                      className = "navPersonal"
                      onClick   = {() => this.props.closeAndScroll('navPersonal')}
                >
                    <h1>Who I am</h1>
                    <SvgArrow className = "linkIcon"/>
                    <h5>learn more</h5>
                </Link>

                <div className = "imprint">
                    <Link to      = "/imprint"
                          onClick = {this.props.closeMenu}
                    >
                        <h6>&nbsp;Imprint</h6>
                    </Link>
                    <h6>&nbsp;|&nbsp;</h6>
                    <Link to      = "/data_privacy"
                          onClick = {this.props.closeMenu}
                    >
                        <h6>Data Privacy</h6>
                    </Link>
                </div>

                <h6 className = "copyright" >&copy;{" David Kaipf " + this.year}</h6>
            </div>
        )
    }
}

