import React                from 'react';
import                           '../../../css/modules/ProjectList.css';

import {ProjectEntry}       from '../../components/ProjectEntry';

//Teaser Images
import senquenz1 from '../../../img/projects/01_senquenz/Vorschau_1.jpg';
import senquenz2 from '../../../img/projects/01_senquenz/Vorschau_2.jpg';
import fundus1   from '../../../img/projects/02_fundus/Vorschau_1.jpg';
import fundus2   from '../../../img/projects/02_fundus/Vorschau_2.jpg';
import samurai1  from '../../../img/projects/03_samurai/Vorschau_1.jpg';
import samurai2  from '../../../img/projects/03_samurai/Vorschau_2.jpg';
import motionS1  from '../../../img/projects/04_motionStud/Vorschau_1.jpg';
import motionS2  from '../../../img/projects/04_motionStud/Vorschau_2.jpg';
import vaext1    from '../../../img/projects/05_vaext/Vorschau_1.jpg';
import vaext2    from '../../../img/projects/05_vaext/Vorschau_2.jpg';
import digital1  from '../../../img/projects/06_digital_physical/Vorschau_1.jpg';
import digital2  from '../../../img/projects/06_digital_physical/Vorschau_2.jpg';
import verdana1  from '../../../img/projects/07_verdana/Vorschau_1.jpg';
import verdana2  from '../../../img/projects/07_verdana/Vorschau_2.jpg';

export class ProjectList extends React.PureComponent{
    constructor(props) {
        super(props);

        this.state = {
            currentInfo:    '',
            info:           false,
            teaser1:        senquenz1,
            teaser2:        senquenz2,
            calledHide:     false
        }
    }

    changeInfo(info, teaser1, teaser2){
        this.setState({
            info:       false,
            calledHide: false
        });

        window.setTimeout(() => {
            this.setState({
                currentInfo: info,
                teaser1:     teaser1,
                teaser2:     teaser2
            });

            window.setTimeout( () => {
                if(this.state.calledHide === false){
                    this.setState({
                        info:   true
                    });
                }

            }, 200);

        }, 500);
    }

    hideInfo(){
        this.setState({
            info:       false,
            calledHide: true
        });
    }

    render() {
        let lights = this.props.defaulLights;

        return(
            <div className = "listContainer" id = 'project'>
                <h2 className = "midLine" >Projects</h2>

                <ProjectEntry
                    title = "Senquenz"
                    year  = "2021"
                    key1  = "motion"
                    key2  = "code"
                    key3  = "concept"
                    link  = "/senquenz"
                    keygradient = {[lights.red, lights.blue, lights.yellow]}
                    changeLightning = {() => this.props.changeLightning(lights.blue, lights.red, lights.yellow)}
                    infoText        = "Web-based real-time music visualization based on your current location (bachelor thesis)."
                    changeInfo      = {(info) => this.changeInfo(info, senquenz1, senquenz2 )}
                    hideInfo        = {() => this.hideInfo()}
                    isMobile        = {this.props.isMobile}
                />

                <ProjectEntry
                    title = "Fundus"
                    year  = "2020"
                    key1  = "concept"
                    link  = "/fundus"
                    keygradient = {[lights.yellow, lights.yellow, lights.blue]}
                    changeLightning = {() => this.props.changeLightning(lights.yellow, lights.blue, lights.yellow)}
                    infoText        = "An easy way to organize artifacts. The web app supports museums in finding and assigning their holdings in a matter of seconds."
                    changeInfo      = {(info) => this.changeInfo(info, fundus1, fundus2 )}
                    hideInfo        = {() => this.hideInfo()}
                    isMobile        = {this.props.isMobile}
                />

                <ProjectEntry
                    title = "Fruit Samurai"
                    year  = "2020"
                    key1  = "code"
                    link  = "/fruitSamurai"
                    keygradient = {[lights.blue, lights.blue, lights.purple]}
                    changeLightning = {() => this.props.changeLightning(lights.blue, lights.blue, lights.purple)}
                    infoText        = "Be the samurai – try to beat your high score in the challenging online game."
                    changeInfo      = {(info) => this.changeInfo(info, samurai1, samurai2 )}
                    hideInfo        = {() => this.hideInfo()}
                    isMobile        = {this.props.isMobile}
                />

                <ProjectEntry
                    title = "Motion Studies"
                    year  = "2019"
                    key1  = "motion"
                    link  = "/motionStudies"
                    keygradient = {[lights.red, lights.red, lights.yellow]}
                    changeLightning = {() => this.props.changeLightning(lights.yellow, lights.red, lights.red)}
                    infoText        = "Some animation and filming projects I created during my studies."
                    changeInfo      = {(info) => this.changeInfo(info, motionS1, motionS2 )}
                    hideInfo        = {() => this.hideInfo()}
                    isMobile        = {this.props.isMobile}
                />

                <ProjectEntry
                    title = "Växt"
                    year  = "2019"
                    key1  = "code"
                    key2  = "concept"
                    link  = "/vaext"
                    keygradient = {[lights.blue, lights.yellow, lights.blue]}
                    changeLightning = {() => this.props.changeLightning(lights.blue, lights.yellow, lights.blue)}
                    infoText        = "A smart greenhouse that helps you to take care of your plants."
                    changeInfo      = {(info) => this.changeInfo(info, vaext1, vaext2 )}
                    hideInfo        = {() => this.hideInfo()}
                    isMobile        = {this.props.isMobile}
                />

                <ProjectEntry
                    title = "Digital to Physical"
                    year  = "2018"
                    key1  = "code"
                    key2  = "concept"
                    link  = "/digitalToPhysical"
                    keygradient     = {[lights.red, lights.blue, lights.purple]}
                    changeLightning = {() => this.props.changeLightning(lights.red, lights.blue, lights.purple)}
                    infoText        = "Projects that show how creative coding can interact with the physical world."
                    changeInfo      = {(info) => this.changeInfo(info, digital1, digital2 )}
                    hideInfo        = {() => this.hideInfo()}
                    isMobile        = {this.props.isMobile}
                />

                <ProjectEntry
                    title = "Verdana."
                    year  = "2018"
                    key1  = "concept"
                    link  = "/verdana"
                    keygradient     = {[lights.yellow, lights.yellow, lights.purple]}
                    changeLightning = {() => this.props.changeLightning(lights.yellow, lights.yellow, lights.purple)}
                    infoText        = "Concept for a website about the font Verdana."
                    changeInfo      = {(info) => this.changeInfo(info, verdana1, verdana2 )}
                    hideInfo        = {() => this.hideInfo()}
                    isMobile        = {this.props.isMobile}
                />

                {window.innerWidth <= 1000? '' :
                    <div
                        className = 'overlay'
                        style     = {this.props.deleteOverlays? {opacity: '0'} : {opacity: '100'}}
                    >
                        <div className = "info"
                             style     = {this.state.info? {opacity: '100'}  : {opacity: '0'}}
                        >
                            <h5 id = "infoContent">
                                {this.state.currentInfo}
                            </h5>
                            <div className = "circle"/>
                        </div>

                        <div className= "positionPictureContainer">
                            <div className = "pictureContainer"
                                 style     = {this.state.info? {opacity: '100'}  : {opacity: '0'}}
                            >
                                <div className = "img1"
                                     style     = {{ backgroundImage: 'url(' + this.state.teaser1 + ')'}}
                                     id        = {this.state.info? 'openImg1' : ''}
                                />
                                <div className = "img2"
                                     style     = {{ backgroundImage: 'url(' + this.state.teaser2 + ')'}}
                                     id        = {this.state.info? 'openImg2' : ''}
                                />
                            </div>
                        </div>
                    </div>
                }



            </div>
        );
    }
}