import React                                        from 'react';
import                                                   '../../../css/ImprintData.css';

import background from '../../../img/icons/wave.svg';

export class Imprint extends React.PureComponent{
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        return(
            <div className = "imDa_Grid"
                 style = {{height: window.innerHeight}}
            >
                <div className= "imprint_container">
                    <h1>Impressum</h1>

                    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>David Kaipf<br />
                        Siedlungsring, 59<br />
                        89415 Lauingen a.d. Donau</p>

                    <h2>Kontakt</h2>
                    <p>Telefon: 017681658326<br />
                        E-Mail: hello@david-kaipf.de</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
                </div>
                <div className= "imDa_background"
                     style={{
                         backgroundImage: 'url('+background+')'
                     }}
                />
            </div>
        )
    }
}