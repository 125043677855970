import React        from 'react';
import * as THREE   from 'three';
import                   '../../../css/modules/VisualsRoot.css';

import {RenderPass}         from 'three/examples/jsm/postprocessing/RenderPass';
import {EffectComposer}     from 'three/examples/jsm/postprocessing/EffectComposer';
import {UnrealBloomPass}    from 'three/examples//jsm/postprocessing/UnrealBloomPass.js';
import {SimplexNoise}       from 'three/examples/jsm/math/SimplexNoise';

export class VisualsRoot extends React.Component{

    componentDidMount() {
        this.rootSetup();
        this.startAnimationLoop();

        this.defaultLights = true;

        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        while (this.scene.children.length > 0){
            this.scene.remove(this.scene.children[0]);
        }

        this.composer.removePass(this.renderPass);
        this.composer.removePass(this.bloomPass);

        window.removeEventListener('resize', this.handleWindowResize);
        window.cancelAnimationFrame(this.requestID);

        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('mousemove', e => {this.onMouseMove(e)});
    }

    rootSetup = () =>{

        //Camera
        this.width = this.el.clientWidth;
        this.height = this.el.clientHeight;

        this.camera = new THREE.PerspectiveCamera(
            75,
            this.width / this.height,
            0.1,
            100
        );
        this.camera.position.z = 80;
        this.camera.position.y = 50;
        this.camera.lookAt(0,0,0);

        //Scene
        this.initScene();

        //Renderer
        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(window.devicePixelRatio/2);
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.el.appendChild(this.renderer.domElement);

        //Effects
        this.composer = new EffectComposer(this.renderer);
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(this.renderPass);

        this.bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ));
        this.bloomPass.strength = 0.3;
        this.composer.addPass(this.bloomPass);
    }

    initScene = () =>{
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(1,1,1);

        //Noise for Plane
        this.simplex = new SimplexNoise();
        this.noiseIntensity = 40;
        this.maxHeight = 10;

        // Plane
        let renderSize = this.getRenderSize();
        this.rWidth = renderSize[0];
        this.rHeight = renderSize[1];

        let material = new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide });
        let geometry = new THREE.PlaneBufferGeometry(this.rWidth, this.rHeight, this.rWidth/3, this.rHeight/3);
        this.plane = new THREE.Mesh(geometry, material);
        this.plane.rotateX(-90);
        this.scene.add(this.plane);


        // Lights
        const r = 30;
        const y = 40;
        const lightDistance = 500;
        const lightIntensity = 0.3;

        this.lightColor = this.props.currentLightning;
        this.light1 = new THREE.PointLight(0xe52e51, lightIntensity, lightDistance);        // Red
        this.light2 = new THREE.PointLight(0xe52e51, lightIntensity, lightDistance);        // Red
        this.light3 = new THREE.PointLight(0xf7d845, lightIntensity, lightDistance);        // Yellow

        this.light4 = new THREE.PointLight(0xc42565, 0.1, lightDistance);           // Purple
        this.light5 = new THREE.PointLight(0x0204ce, lightIntensity, lightDistance);        // Blue
        this.light6 = new THREE.PointLight(0x0204ce, lightIntensity, lightDistance);        // Blue
        // Position in circle
        this.light1.position.set(r*Math.cos(this.toRadians(0)), y, (r*Math.sin(this.toRadians(0))));
        this.light2.position.set(r*Math.cos(this.toRadians(120)), y, (r*Math.sin(this.toRadians(120))));
        this.light3.position.set(r*Math.cos(this.toRadians(240)), y, (r*Math.sin(this.toRadians(240))));

        this.light4.position.set(r*Math.cos(this.toRadians(0)), -y, (r*Math.sin(this.toRadians(0))));
        this.light5.position.set(r*Math.cos(this.toRadians(120)), -y, (r*Math.sin(this.toRadians(120))));
        this.light6.position.set(r*Math.cos(this.toRadians(240)), -y, (r*Math.sin(this.toRadians(240))));

        this.lightTopGroup = new THREE.Group();
        this.lightBottomGroup = new THREE.Group();
        this.lightTopGroup.add(this.light1, this.light2, this.light3);
        this.lightBottomGroup.add(this.light4, this.light5, this.light6);

        this.scene.add(this.lightTopGroup, this.lightBottomGroup);


        // Track Mouse for interaction
        this.mouse         = new THREE.Vector2();
        this.mousePlane    = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
        this.mousePosition = new THREE.Vector3();
        this.raycaster     = new THREE.Raycaster();

        document.addEventListener('mousemove', e => {this.onMouseMove(e)});

        // Camera Movement on Scroll
        document.addEventListener('scroll', this.onScroll);

    }

    onScroll = () =>{
        let offset = window.scrollY;

        if(offset < this.props.endOfHome * 0.7){
            // Start > Projects
            this.camera.position.z = this.projectValToInterval(offset, 0, 700, 80, 47);
            this.camera.position.y = this.projectValToInterval(offset, 0, 700, 50, 37);
            this.defaultLights = true;

        }else if(offset >= this.props.endOfHome * 0.7 && offset < this.props.endOfProjects-this.props.endOfHome * 0.5){
            // Projects
            this.camera.position.z = 47;
            this.camera.position.y = 37;

            this.defaultLights = false;

        }else if(offset > this.props.endOfProjects-this.props.endOfHome * 0.5){
            // Projects > About
            let min = this.props.endOfProjects-500;
            let max = this.props.endOfProjects+500;
            this.camera.position.z = this.projectValToInterval(offset, min, max, 47, 80);
            this.camera.position.y = this.projectValToInterval(offset, min, max, 37, 50);

            this.defaultLights = true;
        }
    }

    onMouseMove = (e) =>{
        let v = new THREE.Vector3();
        this.camera.getWorldDirection(v);
        v.normalize();

        this.mousePlane.normal = v;
        this.mouse.x = (e.clientX / this.width) -1;
        this.mouse.y = - (e.clientY / this.height) +1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        this.raycaster.ray.intersectPlane(this.mousePlane, this.mousePosition);
    }

    startAnimationLoop = () =>{
        // Animation
        this.animatePlane();
        this.animateLights();

        // Render
        this.composer.render();
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    }

    animatePlane = () =>{
        let gArray = this.plane.geometry.attributes.position.array;
        const time = Date.now() * 0.0002;

        for(let i =0; i < gArray.length; i+=3){
            gArray[i +2] = this.simplex.noise4d(gArray[i] / this.noiseIntensity,
                                                gArray[i +1] / this.noiseIntensity,
                                                    time,
                                                this.mouse.x + this.mouse.y) * this.maxHeight;
        }
        this.plane.geometry.attributes.position.needsUpdate = true;

    }

    animateLights = () => {
        this.lightTopGroup.rotateY(0.01);
        this.lightBottomGroup.rotateY(-0.01);


        // Change Lightcolor on Hover if not Default

        if (this.defaultLights) {
            if (this.lightColor !== this.props.upperLightsDefault) {
                this.light1.color = new THREE.Color(this.props.upperLightsDefault[0]);
                this.light2.color = new THREE.Color(this.props.upperLightsDefault[1]);
                this.light3.color = new THREE.Color(this.props.upperLightsDefault[2]);
                this.lightColor = this.props.upperLightsDefault;
            }

        } else {
            if (this.lightColor !== this.props.currentLightning) {
                this.light1.color = new THREE.Color(this.props.currentLightning[0]);
                this.light2.color = new THREE.Color(this.props.currentLightning[1]);
                this.light3.color = new THREE.Color(this.props.currentLightning[2]);
                this.lightColor = this.props.currentLightning;
            }

        }
    }


    handleWindowResize = () => {
        this.width  = this.el.clientWidth;
        this.height = this.el.clientHeight;

        this.renderer.setSize(this.width, this.height);
        this.camera.aspect = this.width / this.height;
        this.composer.setSize(this.width, this.height);

        let renderSize = this.getRenderSize();
        this.rWidth = renderSize[0];
        this.rHeight = renderSize[1];

        this.camera.updateProjectionMatrix();

        document.removeEventListener('scroll', this.onScroll);
        document.addEventListener('scroll', this.onScroll);
    };

    getRenderSize(){
        let cam = new THREE.PerspectiveCamera(this.camera.fov, this.camera.aspect);
        let vFOV = cam.fov * Math.PI / 180;
        let height = 2 * Math.tan(vFOV / 2) * Math.abs(75);
        let width = height * cam.aspect;
        return [width, height];
    }

    toRadians(angle){
        return angle * (Math.PI / 180);
    }

    // Helps with mapping, projects a value from a given interval to another
    projectValToInterval(oldVal, oldMin, oldMax, newMin, newMax){
        let m = (newMax - newMin)/(oldMax - oldMin);
        let n = - ( m * oldMin ) + newMin;
        return oldVal * m +n;
    }




    render() {
        return(
            <div>
                <div className = "overlay"/>
                <div className = "visualsContainer" ref = {ref => (this.el = ref)}/>
            </div>
        )
    }
}