import React                                        from 'react';
import {HashRouter as Router, Route, Switch}        from 'react-router-dom';

import                                                   '../css/App.css';

import {Nav}                from './components/Nav';
import {Start}              from './pages/homepage/Start';
import {ProjectList}        from './pages/homepage/ProjectList';
import {AboutMe}            from './pages/homepage/AboutMe';
import {VisualsRoot}        from './pages/homepage/VisualsRoot';
import {Dialog}             from './components/Dialog';
import {Footer}             from './components/Footer';

import {Imprint}            from './pages/imprint/Imprint';
import {DataPrivacy}        from './pages/imprint/DataPrivacy';
import {PageNotFound}       from './pages/PageNotFound';

// Project Pages
import {Senquenz}           from './pages/projectpages/Senquenz';
import {Fundus}             from './pages/projectpages/Fundus';
import {FruitSamurai}       from './pages/projectpages/FruitSamurai';
import {MotionStudies}      from './pages/projectpages/MotionStudies';
import {Vaext}              from './pages/projectpages/Vaext';
import {DigitalPhysical}    from './pages/projectpages/DigitalPhysical';
import {Verdana}            from './pages/projectpages/Verdana';

// config
import p_config             from '../config/projects.json';



export class App extends React.PureComponent {

    constructor(props) {
        super(props);

        this.projectsRef = React.createRef();
        this.aboutRef    = React.createRef();
        this.startRef    = React.createRef();

        this.defaultLights = {
            red:    '#e52e51',
            yellow: '#f7d845',
            purple: '#c42565',
            blue:   '#0204ce'
        };

        this.upperLightSetup = [this.defaultLights.red, this.defaultLights.red, this.defaultLights.yellow];

        this.state = {
            // Is landingpage loaded
            loaded: false,

            // Start.jsx stop scroll animation
            scrollAnimation: true,

            //Dialog
            openDialog:     false,
            dialogLink:     undefined,
            dialogContent:  '',

            // Lightning
            currentLightning: ['#e52e51', '#e52e51', '#f7d845'],

            // Parameters for scrolling
            documentHeight:     0,
            homeHeight:         0,
            endOfProjects:      0,
            deleteOverlays:     false,

            isMobile:          false,
            windowHeight:      0

        };
    }

    componentDidMount() {
        this.checkLoadStatus();

        window.addEventListener('resize', this.onWindowResize);

        document.addEventListener('scroll', this.onDocumentScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        document.removeEventListener('scroll', this.onDocumentScroll);
        window.clearTimeout(this.delayColorChange);
    }

    onDocumentScroll = () =>{
        let offset = window.scrollY;

        if(offset < this.state.homeHeight*0.7){
            this.setState({
                deleteOverlays: true
            });
        }else if(offset >= this.state.homeHeight*0.7 && offset < (this.state.endOfProjects - this.state.homeHeight*0.3)){
            this.setState({
                deleteOverlays: false
            });
        }else if(offset >= (this.state.endOfProjects - this.state.homeHeight*0.3)){
            this.setState({
                deleteOverlays: true
            });
        }

        if(offset > 120){
            this.setState({
                scrollAnimation: false
            });
        }else{
            this.setState({
                scrollAnimation: true
            });
        }
    }


    scrollTo(elementName){
        this.scrollElement = elementName;
        this.checkLoadStatus()
    }

    // Checks if document is loaded and scroll to position
    checkLoadStatus(){
        if(this.state.loaded === false){
            setTimeout(() => this.checkLoadStatus(), 50);

        }else if(this.state.loaded === true){
            if(this.scrollElement === 'navProjects'){
                this.projectsRef.current.scrollIntoView({behavior: "smooth"});
            }else if(this.scrollElement === 'navPersonal'){
                this.aboutRef.current.scrollIntoView({behavior: "smooth"});
            }else if(this.scrollElement === 'navStart'){
                this.startRef.current.scrollIntoView({behavior: "smooth"});
            }
            this.onWindowResize();
        }
    }

    setLoad(boolean){
        this.setState({
            loaded: boolean
        });
    }

    // Change lightning of three animation
    changeLightning(light1, light2, light3, longCall){

        window.clearTimeout(this.delayColorChange);
        this.delayColorChange = window.setTimeout(()=> this.changeLightning(light1, light2, light3, true), 300);

        if(longCall){
            let oldState = this.state.currentLightning;

            if (oldState[0] !== light1 || oldState[1] !== light2 || oldState[2] !== light3) {

                this.setState({
                    currentLightning: [light1, light2, light3]
                });
            }
        }



    }

    openDialog = (content, link = undefined) =>{
        this.setState({
            openDialog: true,
            dialogLink: link,
            dialogContent: content,
        });
    }

    closeDialog = () =>{
        this.setState({
            openDialog: false
        });
    }

    onWindowResize = () =>{
        // Position parameters for scrolling
        if(document.getElementById('project')){
            let body = document.body,
                html = document.documentElement;

            let height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);

            let homeHeight = window.innerHeight;
            let projectHeight = document.getElementById('project').offsetHeight;

            this.setState({
                documentHeight: height,
                endOfProjects: homeHeight + projectHeight,
                homeHeight: homeHeight
            });


            // is it a mobile device?
            this.setState({
                isMobile:       this.isMobile(),
                windowHeight:   window.innerHeight
            });
        }


        document.removeEventListener('scroll', this.onDocumentScroll);
        document.addEventListener('scroll', this.onDocumentScroll);
    }

    isMobile(){
        if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
            return true;
        }else if(navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2){
            return true;
        }else{
            return false;
        }
    }

    render() {
        return(
            <Router>
                <Nav
                    openMenu    = {() => this.openMenu()}
                    closeMenu   = {() => this.closeMenu()}
                    scrollTo    = {(name) => this.scrollTo(name)}
                    openDialog  = {(content, link) => this.openDialog(content, link)}
                />

                {this.state.openDialog?
                    <Dialog
                        link        = {this.state.dialogLink}
                        content     = {this.state.dialogContent}
                        closeDialog = {() => this.closeDialog()}
                    />
                    : ''
                }

                <Switch>
                    <Route exact path = "/">
                        <VisualsRoot
                            currentLightning   = {this.state.currentLightning}
                            upperLightsDefault = {this.upperLightSetup}
                            documentHeight     = {this.state.documentHeight}
                            endOfHome          = {this.state.homeHeight}
                            endOfProjects      = {this.state.endOfProjects}
                        />

                        <div ref={this.startRef}>
                            <Start
                                   openDialog      = {(content, link) => this.openDialog(content, link)}
                                   scrollAnimation = {this.state.scrollAnimation}
                                   height          = {this.state.windowHeight}
                            />
                        </div>

                        <div ref={this.projectsRef}>
                            <ProjectList
                                changeLightning = {(l1, l2, l3) => this.changeLightning(l1, l2, l3)}
                                defaulLights    = {this.defaultLights}
                                deleteOverlays  = {this.state.deleteOverlays}
                                isMobile        = {this.state.isMobile}
                            />
                        </div>

                        <div ref={this.aboutRef}>
                            <AboutMe
                                setLoad = {(boolean)=> this.setLoad(boolean)}
                            />
                        </div>
                    </Route>

                    <Route exact path = "/senquenz">
                        <Senquenz
                            scrollTo      = {(name) => this.scrollTo(name)}
                            defaultLights = {this.defaultLights}
                            config        = {p_config}
                        />
                    </Route>

                    <Route exact path = "/fundus">
                        <Fundus
                            scrollTo      = {(name) => this.scrollTo(name)}
                            defaultLights = {this.defaultLights}
                            config        = {p_config}
                        />
                    </Route>

                    <Route exact path = "/fruitSamurai">
                        <FruitSamurai
                            scrollTo      = {(name) => this.scrollTo(name)}
                            defaultLights = {this.defaultLights}
                            config        = {p_config}
                        />
                    </Route>

                    <Route exact path = "/motionStudies">
                        <MotionStudies
                            scrollTo      = {(name) => this.scrollTo(name)}
                            defaultLights = {this.defaultLights}
                            config        = {p_config}
                        />
                    </Route>

                    <Route exact path = "/vaext">
                        <Vaext
                            scrollTo      = {(name) => this.scrollTo(name)}
                            defaultLights = {this.defaultLights}
                            config        = {p_config}
                        />
                    </Route>

                    <Route exact path = "/digitalToPhysical">
                        <DigitalPhysical
                            scrollTo      = {(name) => this.scrollTo(name)}
                            defaultLights = {this.defaultLights}
                            config        = {p_config}
                        />
                    </Route>

                    <Route exact path = "/verdana">
                        <Verdana
                            scrollTo      = {(name) => this.scrollTo(name)}
                            defaultLights = {this.defaultLights}
                            config        = {p_config}
                        />
                    </Route>

                    <Route exact path = "/imprint">
                        <Imprint/>
                    </Route>

                    <Route exact path = "/data_privacy">
                        <DataPrivacy/>
                    </Route>

                    <Route path = "*">
                        <PageNotFound/>
                    </Route>

                </Switch>

                <Footer
                    openDialog = {(content, link) => this.openDialog(content, link)}
                />
            </Router>


        );
    }
}