import React                                        from 'react';
import {ProjectBackground}  from '../../components/projectPage/ProjectBackground';
import {ProjectHeader}      from '../../components/projectPage/ProjectHeader';
import {ProjectTeaser}      from '../../components/projectPage/ProjectTeaser';
import {ProjectTextPicture} from '../../components/projectPage/ProjectTextPicture';

import header   from '../../../img/projects/05_vaext/Project_Header.jpg';
import vaext1   from '../../../img/projects/05_vaext/Text_Bild_A.jpg';
import vaext2   from '../../../img/projects/05_vaext/Text_Bild_B.jpg';
import vaext3   from '../../../img/projects/05_vaext/Teaser_1_A.jpg';
import vaext4   from '../../../img/projects/05_vaext/Text_Bild_C.jpg';
import vaext5   from '../../../img/projects/05_vaext/Text_Bild_D.jpg';
import vaext6   from '../../../img/projects/05_vaext/Teaser_1_B.jpg';
import vaext7   from '../../../img/projects/05_vaext/Teaser_2_A.jpg';
import vaext8   from '../../../img/projects/05_vaext/Teaser_2_B.jpg';



export class Vaext extends React.PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {

        let skills = this.props.config.skills;
        let tools = this.props.config.tools;
        let team = this.props.config.team;

        return (
            <div>
                <ProjectBackground
                    colors = {[this.props.defaultLights.yellow, this.props.defaultLights.blue, this.props.defaultLights.blue]}
                />

                <ProjectHeader
                    headline    = "Växt"
                    year        = "2019"
                    teaser      = {header}
                    teaserALT   = "vaext website"
                    shortInfo   = "Based on an increasing trend of self-sufficiency, the idea was born to design an intelligent greenhouse called Växt. Cultivation, growth and harvesting of plants made easy! The greenhouse is equipped with various sensors and a server connection. It can inform the user through the website to create the perfect conditions.
                    "

                    skills      = {[skills.ux, skills.code, skills.code_b, skills.project, skills.electric]}
                    tools       = {[tools.raspberry, tools.python, tools.a_xd]}
                    team        = {[team.rike]}

                    scrollTo    = {() => this.props.scrollTo('navProjects')}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {vaext1}
                    headline    = "Complexity Assessment"
                    content     = "First of all, we needed to determine the requirements that are needed to create a smarter greenhouse. To finish the project in time, those functions got evaluated by the workload and ranked by importance.
                    "
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {vaext2}
                    headline    = "Website"
                    content     = "The users can check the current temperature and humidity of their greenhouses after they logged into their account. A diagram tells the minimum, maximum and average temperature of the last seven days, so it's not necessary to open the website all the time. There will be a notification if the plants need to be watered. The overall interface is very minimal to set the focus on the facts.
                    "
                />

                <ProjectTeaser
                    pictures   = {[vaext3]}
                />

                <ProjectTextPicture
                    imgLeft     = {false}
                    img         = {vaext4}
                    headline    = "Implementation"
                    content     = "The Raspberry Pi handles two main tasks. On one side, it communicates with the sensors and processes the received data. On the other side, it creates a local server which is accessible from devices within the same network. The Raspberry Pi collects the data and embeds it into a website that can be called by the users.
                    "
                />

                <ProjectTextPicture
                    imgLeft     = {true}
                    img         = {vaext5}
                    headline    = "Build"
                />

                <ProjectTeaser
                    pictures   = {[vaext7, vaext8]}
                />

                <ProjectTeaser
                    pictures   = {[vaext6]}
                />

            </div>
        )
    }
}
