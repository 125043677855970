import React                from 'react';
import                           '../../../css/modules/Start.css';

export class Start extends React.PureComponent{

    render() {
        return(
                <div className = "startScreen" id = 'home'
                     style = {{height: this.props.height}}
                >
                    <h1>
                        Combining<br/>
                        worlds<br/>
                        is my<br/>
                        passion
                    </h1>

                    <div className= "startParagraph">
                        <h2>
                            Hi, I'm a multidisciplinary creative developer. I have a passion for mixing design and coding to create unique and interesting experiences.<br/>
                            Feel free to check out my work below.
                        </h2>
                        <h6>
                            <span
                               onClick = {(e) => this.props.openDialog('LinkedIn/David-Kaipf', 'https://www.linkedin.com/in/david-kaipf-0b74a01b0')}
                            >
                                LinkedIn
                            </span>
                            &nbsp;|&nbsp;
                            <span
                               onClick = {(e) => this.props.openDialog('Behance/DavidKaipf', 'https://www.behance.net/davidkaipf')}
                            >
                                Behance
                            </span>
                            &nbsp;|&nbsp;
                            <span
                               onClick = {(e) => this.props.openDialog('Instagram/DK_Creative_Logician', 'https://www.instagram.com/dk_creative_logician/')}
                            >
                                Instagram
                            </span>
                        </h6>
                    </div>


                    <div className="followMe" id = {this.props.scrollAnimation? '' : 'hideScroll'}>
                        <div className="dot"/>
                        <div className="dot" style={{'animationDelay': '0.3s'}}/>
                        <div className="dot" style={{'animationDelay': '0.6s'}}/>
                    </div>



                </div>

        );
    }
}