import React                                        from 'react';
import {Link}                                       from 'react-router-dom';
import {Menu}                                       from './Menu';
import {LetsTalkAnimation}                          from './LetsTalkAnimation';
import                                                   '../../css/components/Nav.css';

import {ReactComponent as SvgLogo}                  from '../../img/icons/logo.svg';
import {ReactComponent as SvgMenu}                  from '../../img/icons/menu.svg';
import {ReactComponent as SvgClose}                 from '../../img/icons/close.svg';


export class Nav extends React.PureComponent{

    constructor(props) {
        super(props);

        this.state = {
            visibleMenu:    false,
            hideMenu:       true,
            call:           false
        }
    }

    openMenu = () =>{
        if(!this.state.call){
            this.setState({
                hideMenu:       false,
                visibleMenu:    true
            });
        }
    }

    closeMenu = () =>{

        if(this.state.visibleMenu){
            if(!this.state.call){
                this.setState({
                    visibleMenu:    false,
                    call:           true
                });

                if(!this.state.hideMenu){
                    window.setTimeout(() => {
                        this.setState({
                            hideMenu:   true,
                            call:       false
                        });
                    }, 1200);
                }
            }
        }
    }

    closeAndScroll = (scrollName) =>{
        this.closeMenu();
        this.props.scrollTo(scrollName);
    }

    render() {
        return(
            <div className = "navContainer"
                 id = {this.state.hideMenu? '' : 'fullSize'}
            >
                <Link to = "/"
                      onClick = {() => this.closeAndScroll('navStart')}
                      className = "logo_frame"
                >
                    <SvgLogo id = "logo"/>
                </Link>


                {this.state.visibleMenu?
                    <div
                        className = "menuButton"
                        onClick   = {this.closeMenu}
                    >
                        <SvgClose className = "menuButton_frame"/>
                    </div>
                :
                    <div>
                        <div
                            className = "menuButton"
                            onClick   = {this.openMenu}
                        >
                            <SvgMenu className = "menuButton_frame"/>
                        </div>

                        <a href    = "mailto:hello@david-kaipf.de"
                           onClick = {() => this.props.openDialog('Mail me at: hello@david-kaipf.de')}
                           className = "navTalk"
                        >
                            <LetsTalkAnimation
                                className = "navTalk_frame"
                            />
                        </a>
                    </div>
                }


                {this.state.hideMenu? '' :
                    <Menu
                        closeAndScroll      = {(name) => this.closeAndScroll(name)}
                        visibleMenu         = {this.state.visibleMenu}
                        openDialog          = {(content, link) => this.props.openDialog(content, link)}
                        closeMenu           = {() => this.closeMenu()}
                    />
                }

            </div>

       )
    }
}

