import React                                        from 'react';
import                                                   '../../../css/components/projectPage/ProjectBackground.css';

export class ProjectBackground extends React.PureComponent{


    render() {
        return(
            <div className = "p_Background"
                 style = {{height: window.innerHeight,}}
            >

                <div className = "p_gradient1"
                     style = {{
                         background: 'radial-gradient(circle,' + this.props.colors[0] + ' 0%, rgb(1,1,1,0) 60%)'
                     }}
                />
                <div className = "p_gradient2"
                     style = {{
                         background: 'radial-gradient(circle,' + this.props.colors[1] + ' 0%, rgb(1,1,1,0) 60%)'
                     }}
                />
                <div className="p_gradient3"
                     style = {{
                         background: 'radial-gradient(circle,' + this.props.colors[2] + ' 0%, rgb(1,1,1,0) 60%)'
                     }}
                />
                <div className = "p_background_blur"/>

            </div>


        )
    }
}

