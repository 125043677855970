import React                                        from 'react';
import                                                   '../../css/components/Footer.css';

import {ReactComponent as SvgCI}                  from '../../img/icons/ci.svg';
import {ReactComponent as SvgArrow}                 from '../../img/icons/arrow.svg';
import {Link} from "react-router-dom";

export class Footer extends React.PureComponent{

    constructor(props) {
        super(props);

        this.year = new Date().getFullYear();
    }

    render() {
        return(
            <div className="footerContainer">
                <div className="whitespace">
                    <h1>Let's Talk</h1>
                    <SvgArrow className = "linkIcon"/>
                    <a href = "mailto:hello@david-kaipf.de"
                       onClick = {(e) => this.props.openDialog('Mail me at: hello@david-kaipf.de')}
                    >
                        <h5>hello@david-kaipf.de</h5>
                    </a>
                </div>


                <div className="leftSide">
                    <h6 className="c">&copy;{" David Kaipf " + this.year + " | "}</h6>
                    <Link to="/imprint">
                        <h6>&nbsp;Imprint</h6>
                    </Link>
                    <h6>&nbsp;|&nbsp;</h6>
                    <Link to="/data_privacy">
                        <h6>Data Privacy</h6>
                    </Link>
                </div>

                <div className="middle">
                    <SvgCI/>
                </div>

                <div className="rightSide">
                    <h6>
                        <span
                           onClick = {(e) => this.props.openDialog('LinkedIn/David-Kaipf', 'https://www.linkedin.com/in/david-kaipf-0b74a01b0')}
                        >
                            LinkedIn
                        </span>
                        &nbsp;|&nbsp;
                        <span
                           onClick = {(e) => this.props.openDialog('Behance/DavidKaipf', 'https://www.behance.net/davidkaipf')}
                        >
                            Behance
                        </span>
                        &nbsp;|&nbsp;
                        <span
                           onClick = {(e) => this.props.openDialog('Instagram/DK_Creative_Logician', 'https://www.instagram.com/dk_creative_logician/')}
                        >
                            Instagram
                        </span>
                    </h6>
                </div>


            </div>
        )
    }
}

