import React                                        from 'react';
import {ProjectBackground}  from '../../components/projectPage/ProjectBackground';
import {ProjectHeader}      from '../../components/projectPage/ProjectHeader';
import {ProjectTeaser}      from '../../components/projectPage/ProjectTeaser';

import header from '../../../img/projects/06_digital_physical/Project_Header.jpg';
import ss1    from '../../../img/projects/06_digital_physical/Teaser_2_SS_A.jpg';
import ss2    from '../../../img/projects/06_digital_physical/Teaser_2_SS_B.jpg';
import ss3    from '../../../img/projects/06_digital_physical/Teaser_1_SS_A.jpg';
import col1   from '../../../img/projects/06_digital_physical/Teaser_2_CoL_A.jpg';
import col2   from '../../../img/projects/06_digital_physical/Teaser_2_CoL_B.jpg';
import col3   from '../../../img/projects/06_digital_physical/Teaser_1_CoL_A.jpg';



export class DigitalPhysical extends React.PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {

        let skills = this.props.config.skills;
        let tools = this.props.config.tools;
        let team = this.props.config.team;

        return (
            <div>
                <ProjectBackground
                    colors={[this.props.defaultLights.red, this.props.defaultLights.blue, this.props.defaultLights.purple]}
                />

                <ProjectHeader
                    headline="Social Solution"
                    year="2018"
                    teaser="https://www.youtube-nocookie.com/embed/2ETPJGmdnEw"
                    teaserALT="Info Video Social Solution"
                    shortInfo="The solution for an isolated world. To encounter the behavior of people spending more time online and losing social interaction, our team designed an interactive exhibition that encourages the visitor’s social behavior. The people practice human interactions with everyday objects that react in color and sound to individual contact. A dark room can be lightened up thanks to a projection animation that triggers when a lot of interactions are observed. The prototype “hugy hugy” was built to show how the exhibition concept could work.
                    "
                    skills = {[skills.research, skills.code_c, skills.electric]}
                    tools  = {[tools.processing, tools.arduino, tools.a_ps]}
                    team   = {[team.flo, team.simon]}

                    scrollTo={() => this.props.scrollTo('navProjects')}
                />

                <ProjectTeaser
                    pictures = {[ss1, ss2]}
                />

                <ProjectTeaser
                    pictures = {[ss3]}
                />

                <ProjectHeader
                    headline = "Cost of Living"
                    year     = "2018"
                    teaser   = {header}
                    teaserALT="poster of information design"
                    shortInfo="Are there any relations between the general living costs, the pollution and the crime rate of a country? The information poster graphic compares 62 countries in 3 different years, displayed in an abstract and aesthetic way. Since this is a huge mass of data, the IDE Processing was used to generate the diagram. This makes it also easy to update the graphics at any time. In Illustrator, the exported graphs were recolored to create an interesting poster.
                    "
                    skills = {[skills.research, skills.code_c, skills.layout]}
                    tools  = {[tools.processing, tools.a_ai, tools.a_id]}

                    scrollTo = {() => this.props.scrollTo('navProjects')}
                    noBackButton = {true}
                />

                <ProjectTeaser
                    pictures = {[col1, col2]}
                />

                <ProjectTeaser
                    pictures = {[col3]}
                />

            </div>
        )
    }
}
